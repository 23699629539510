<template>
  <div class="float-left form-data justify-center">
    <h3 class="mb-5 text-center">
      {{ $t("examination.generalCheckUp.generalOptomologyTest") }}
    </h3>
    <div fluid class="examination-form mb-10">
      <div fluid class="ma-0 pa-0 mt-2">
        <!-- <OMComponent :dataPoints="dataPoints" /> -->
        <component
          :is="compName"
          :dataPoints="dataPoints"
          style="margin-top: -1px"
        />
      </div>
    </div>
    <v-bottom-navigation
      grow
      height="50"
      absolute
      class="elevation-0 exam-bottom-controller"
      hide-on-scroll
    >
      <v-btn
        class="pl-10 pr-10 white--text"
        color="#0065FF"
        @click="saveOptomologyDataPoints"
        v-bind:disabled="isFinishExamination === true ? true : false"
      >
        {{ $t("common.SaveData") }}
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import DataPointService from "@/services/dataPoint";
import OMComponent from "@/components/examination/components/Composites/OMComponent";
import WorkListService from "@/services/work-list";
import EpisodeService from "@/services/episode";
import GeneralExamService from "@/services/generalExam";
import i18n from "@/plugins/i18n";

export default {
  components: {
    OMComponent,
  },
  data() {
    return {
      compName: "GeneralExamComponent",
      episodeRecordID: this.$route.params.episodeRecordID,
      dataPoints: [],
      episodeID: null,
      isFinishExamination: false,
    };
  },
  // when examination page is first loaded
  async created() {
    // get data point via APIs only once
    await this.renderOptomologyDataPoints();
    await this.getEpisodeID(this.$route.params.episodeRecordID);
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    async getEpisodeID(episodeRecordID) {
      if (!episodeRecordID) return;
      var result = await WorkListService.GetByEpisodeRecordID(episodeRecordID);
      if (!result || result.error) {
        this.showError(
          "Cannot get episodeID at the moment! Please try again later."
        );
      }
      this.episodeID = result.episodeID;
      await this.getEpisodeInfo();
    },
    async getEpisodeInfo() {
      const result = await EpisodeService.getEpisodeDetail(this.episodeID);
      if (!result || result.error) {
        this.showError(
          "Can not get episode information. Please try again later."
        );
        return;
      }
      if (result.statusID == 2) {
        this.isFinishExamination = true;
      }
    },

    // get Last GeneralExamination via last episodeRecordID that has data in dataPoints
    async getLastGeneralExaminationDatapoint() {
      var patientID = this.$route.params.patientId;
      var currentEpisode = this.$route.params.episodeRecordID;
      var result = await GeneralExamService.getLastGeneralExamination(
        patientID,
        currentEpisode
      );
      if (result.error) {
        this.$toast.error(result.message);
        return;
      }

      var lastEpisodeRecord = result.episodeRecordID;
      if (this.dataPoints.length == 0) {
        this.dataPoints = result.gnrDataPoint;
      } else {
        // if lastEpisodeRecordID that is queried from api not equals currentEpisode => add dataPoint of GNRE to current Datapoint
        if (
          currentEpisode != lastEpisodeRecord &&
          result.gnrDataPoint.length > 0
        ) {
          this.dataPoints = this.dataPoints.concat(result.gnrDataPoint);
        }
      }
    },

    // this function get all data-points and cached into array
    async renderOptomologyDataPoints() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      if (!episodeRecordID) return;
      var result = await DataPointService.searchDataPoint(episodeRecordID);
      if (!result || result.error) {
        this.showError(
          "Cannot get form data-point at the moment! Please try again later."
        );
      }
      this.dataPoints = result.items;
      this.getLastGeneralExaminationDatapoint();
    },
    // this function save data-points collection
    // then complete the order item selected
    async saveOptomologyDataPoints() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      var dataPoints = this.dataPoints;
      // update collection data-points
      var patientID = this.$route.params.patientId;
      var result = await DataPointService.postCollection(
        episodeRecordID,
        patientID,
        dataPoints
      );
      if (!result || result.error) {
        this.$toast.error(
          "Cannot save data points due to invalid episode record"
        );
        return;
      }
      this.showSuccess(i18n.t("message.generalCheckUp"));
    },
  },
};
</script>

<style lang="scss">
.form-data {
  width: 100%;
}
.exam-bottom-controller {
  background-color: #f5f5f5 !important;
  button {
    background-color: #0277bd !important;
    .v-btn__content {
      color: white !important;
      font-size: 120%;
      text-transform: uppercase;
    }
  }
  button:disabled {
    background-color: #bdbdbd !important;
    .v-btn__content {
      color: #424242 !important;
    }
  }
}
.v-item-group.v-bottom-navigation {
  display: flex;
  width: calc(100% - 16px) !important;
  bottom: 15px;
}
</style>

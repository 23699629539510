import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Emr);

const prefix = '/GeneralCheckup';

export default {
  getLastGeneralExamination: (patientID, currentEpisodeRecordID) => httpClient.get(`${prefix}/LastGeneralExam/${patientID}`,{currentEpisodeRecordID}),
  getGeneralExamProgress: (patientID, page = 1, limit = 10) => httpClient.get(`${prefix}/Progress/${patientID}`, {}, { page, limit})

}